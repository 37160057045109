import React from 'react';
import App, { Container } from 'next/app';
import Router from 'next/router';
import { setRuntimeVariables } from '../lib/runtimeVariables';

class YMCO extends App {
  constructor(args) {
    super(args);

    this.state = {
      loading: false
    };

    if (args.pageProps.development)
      setRuntimeVariables({
        development: true
      });

    Router.events.on('routeChangeStart', () => {
      document.body.style.transition = 'none';
      document.body.style.opacity = 0.5;

      this.setState({ loading: true });
    });

    Router.events.on('routeChangeComplete', () => {
      document.body.style.opacity = 0;
      document.body.style.transform = 'translate3d(0, -20px, 0)';
      document.body.style.transition = 'none';

      document.body.offsetWidth;

      document.body.removeAttribute('style');
      document.body.style.transition =
        'all 600ms cubic-bezier(0.165, 0.840, 0.440, 1.000)';

      this.setState({ loading: false });
    });

    if (typeof document != 'undefined') {
      document.querySelector('html').classList.add('no-touchevents');
      function addTouchEvents() {
        document.querySelector('html').classList.remove('no-touchevents');
        window.removeEventListener('touchstart', addTouchEvents);
      }
      window.addEventListener('touchstart', addTouchEvents);
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Component {...pageProps} />
      </>
    );
  }
}

export default YMCO;
