let config = {};

function setRuntimeVariables(obj) {
  config = { ...config, ...obj };
}

function getRuntimeVariables() {
  return config;
}

export { setRuntimeVariables, getRuntimeVariables };
